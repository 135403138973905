import React from 'react'
import trash from '../../assets/img/trash.svg'

export const CardSocial = (
    {logo, titulo, url, handEvent}
) => {
    return (
        <div className="border-black border-2 rounded-lg p-3 w-full">
            <div className="flex justify-between">
                <div className="flex justify-between content-center">
                    <img src={logo} alt="twitter" className="w-7 h-7" />
                    <span className="flex items-center ml-2 font-body">{titulo}</span>
                </div>
                <button title="Borrar" onClick={handEvent}>
                    <img src={trash} alt="eliminar" />
                </button>
            </div>
            <p className="text-left font-body mt-2 truncate">{url}</p>
        </div>
    )
}
