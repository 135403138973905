import React from "react";
import Slider from "react-slick";
import linkedinImg from "../../assets/social_icons/linkedin.png"
import email_ from "../../assets/social_icons/email.png"
import phoneImg from "../../assets/social_icons/phone.png"
import whatsapp from "../../assets/social_icons/whatsapp.png"
import facebookp from "../../assets/social_icons/facebook.png"
import instagramp from "../../assets/social_icons/instagram.png"
import twitterp from "../../assets/social_icons/xlogo.png"
import videop from "../../assets/social_icons/video.png"
import microsoftteamsp from "../../assets/social_icons/microsoftteams.png"
import power_logo from "../../assets/social_icons/powerpoint.png"
import word_logo from "../../assets/social_icons/word.png"
import excel_logo from "../../assets/social_icons/excel.png"
import pdf_logo from "../../assets/social_icons/pdf.png"

export default function Carousel({ socialStyle, imageStyle, sliderSettings, email, phone, linkedin, microsoftteams, facebook, instagram, twitter, video, animated, typeOfDevice, project_name,
    excel, powerpoint, pdf, word }) {

    return (
        <Slider {...sliderSettings}>
            <div>
                <a
                    className={socialStyle} href={"mailto:" + email}>
                    <img src={email_} className={imageStyle} alt="Email" />
                </a>
            </div>
            <div>
                <a className={socialStyle} href={"tel:" + phone}>
                    <img src={phoneImg} className={imageStyle} alt="Teléfono" />
                </a>
            </div>
            {project_name !== "voestalpine" &&
                <div>
                    <a
                        className={socialStyle + ` ${animated?.whats_animated && 'animate-bounce'} `} href={"https://wa.me/" + phone}>
                        <img src={whatsapp} className={imageStyle} alt="Whatsapp" />
                    </a>
                </div>
            }
            {linkedin &&
                <div>
                    <a className={socialStyle} href={linkedin.includes("https://www.linkedin.com/in") ? linkedin : "https://www.linkedin.com/in/" + linkedin}>
                        <img src={linkedinImg} className={imageStyle} alt="Linkedin" />
                    </a>
                </div>}
            {microsoftteams &&
                <div>
                    <a className={socialStyle}
                        href={microsoftteams.includes("https://teams.microsoft.com/l/") ? microsoftteams : "https://teams.microsoft.com/l/" + microsoftteams}
                        target="_blank"
                        rel="noreferrer">
                        <img src={microsoftteamsp} className={imageStyle} alt="Microsoft Teams" />
                    </a>
                </div>}
            {facebook &&
                <div>
                    <a className={socialStyle} href={
                        typeOfDevice === "Android" ? (facebook.includes("https://www.facebook.com/") ? `fb://facewebmodal/f?href=${facebook}` : `fb://facewebmodal/f?href=https://www.facebook.com/${facebook}/`) : (
                            typeOfDevice === "iOS" ? (facebook.includes("https://www.facebook.com/") ? `fb://profile/${facebook.replace("https://www.facebook.com/", "")}` : `fb://profile/${facebook}`) : (facebook.includes("https://www.facebook.com/") ? `${facebook}` : `https://www.facebook.com/${facebook}/`)
                        )
                    } >
                        <img src={facebookp} className={imageStyle} alt="Facebook" />
                    </a>
                </div>}
            {instagram &&
                <div>
                    <a className={socialStyle} href={instagram.includes("https://www.instagram.com/") ? `${instagram}` : `https://instagram.com/${instagram}`} >
                        <img src={instagramp} className={imageStyle} alt="Instagram" />
                    </a>
                </div>}
            {twitter &&
                <div>
                    <a className={socialStyle} href={twitter.includes("https://twitter.com/") ? `${twitter}` : `https://twitter.com/${twitter}`}>
                        <img src={twitterp} className={imageStyle} alt="Twitter" />
                    </a>
                </div>}
            {video &&
                <div>
                    <a className={socialStyle} href={`${video}`} target="_blank" rel="noreferrer">
                        <img src={videop} className={imageStyle} alt="VIDEO" />
                    </a>
                </div>}
            {powerpoint &&
                <div>
                    <a className={socialStyle} href={`${powerpoint}`} target="_blank" rel="noreferrer">
                        <img src={power_logo} className={imageStyle} alt="POWERPOINT" />
                    </a>
                </div>}
            {word &&
                <div>
                    <a className={socialStyle} href={`${word}`} target="_blank" rel="noreferrer">
                        <img src={word_logo} className={imageStyle} alt="WORD" />
                    </a>
                </div>}
            {excel &&
                <div>
                    <a className={socialStyle} href={`${excel}`} target="_blank" rel="noreferrer">
                        <img src={excel_logo} className={imageStyle} alt="EXCEL" />
                    </a>
                </div>}
            {pdf &&
                <div>
                    <a className={socialStyle} href={`${pdf}`} target="_blank" rel="noreferrer">
                        <img src={pdf_logo} className={imageStyle} alt="PDF" />
                    </a>
                </div>}
        </Slider>
    );
}