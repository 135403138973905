import React, { useState } from "react"
import { createContext } from "react"
import {
    signInWithEmailAndPassword,
    getAuth,
    signOut,
    sendPasswordResetEmail,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    onAuthStateChanged,
    updateEmail
} from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc, collection, getDocs, deleteField, updateDoc } from "firebase/firestore"
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"
import app from "../services";

export const FirebaseFuntions = createContext()

const FirebaseContext = ({ children }) => {

    const auth = getAuth(app)
    const db = getFirestore(app)
    const storage = getStorage(app)
    const date = new Date()
    const [loginStatus, setLoginStatus] = useState(false)
    const [loginFlag, setLoginFlag] = useState(false)
    const [userProject, setUserProject] = useState("")
    const [idDoc, setIdDoc] = useState()
    const [authID, setAuthID] = useState("")

    const readAllProjects = async () => {
        const docRef = collection(db, "projects")
        const docSnap = await getDocs(docRef)
        const docId = docSnap.docs.map((doc) => doc.id)
        return docId
    }

    const userLogin = async (data) => {
        const { mail, password, company = "promo" } = data
        try {
            const data = await signInWithEmailAndPassword(auth, mail, password)
            const docRef = collection(db, company)
            const docSnap = await getDocs(docRef)
            const docData = docSnap.docs.map((doc) => {
                if (doc.data().authID === data.user.uid) {
                    return doc.id
                } else {
                    return null
                }
            })
            const docFilter = docData.filter((doc) => doc !== null)
            if (docFilter.length !== 0) {
                setIdDoc(docFilter)
                setUserProject(company)
                setLoginFlag(true)
            }
            return ("Sesión iniciada")
        } catch (error) {
            setLoginFlag(false)
            if (error.code === 'auth/wrong-password') {
                return ("Contraseña incorrecta")
            } else if (error.code === 'auth/user-not-found') {
                return ("Usuario no encontrado")
            } else if (error.code === 'auth/invalid-email') {
                return ("Email invalido")
            } else if (error.code === 'auth/too-many-requests') {
                return ("Demasiados intentos fallidos, por favor intente mas tarde")
            }
            else {
                console.log(error)
                return ("Por favor comuniquese con el administrador")
            }
        }
    };

    const userLogout = async () => {
        try {
            await signOut(auth)
            setLoginFlag(false)
            localStorage.removeItem("projectLogo")
            return ("Sesión cerrada")
        } catch (error) {
            console.log(error)
            return ("Por favor comuniquese con el administrador")
        }
    };

    const userForgotPassword = async (data) => {
        const { mail } = data;
        try {
            await sendPasswordResetEmail(auth, mail);
            return ("Se ha enviado un email a su cuenta de correo para restablecer su contraseña");
        } catch (error) {
            if (error.code === 'auth/user-not-found') {
                return ("Usuario no encontrado");
            } else if (error.code === 'auth/invalid-email') {
                return ("Email invalido");
            }
            else {
                console.log(error);
                return ("Por favor comuniquese con el administrador");
            }
        }
    };

    const createUser = async (data) => {
        const { name, lastname, phone, mail, password, userId, projectId, terms, job } = data;
        try {
            const user = await createUserWithEmailAndPassword(auth, mail, password)
            const userRef = doc(db, projectId, userId ? userId : user.user.uid);
            await setDoc(userRef, {
                authID: user.user.uid,
                projectId: projectId,
                userId: userId,
                name: name,
                lastname: lastname,
                email: mail,
                job: job,
                template: projectId === "bh" ? "bh" :
                    projectId === "voestalpine" ? "voestalpine" :
                        projectId === "carnot" ? "carnot" :
                            projectId === "procaps" ? "procaps" :
                                projectId === "ollamani" ? "ollamani" :
                                    projectId === "oxxo" ? "oxxo" :
                                        projectId === "custlog" ? "custlog" :
                                            "promo",
                gender: "male",
                footer: null,
                phone: phone,
                role: "user",
                dateOfCreation: date,
                terms: terms,
                photo: null,
            });
            if (projectId === "promo") {
                await setDoc(userRef, {
                    //el valor sea booleano
                    logo_promoconecta: "true"
                }, { merge: true });
            }
            await sendEmailVerification(user.user); //Envia email de verificacion
            return ("Usuario creado correctamente");
        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                return ("El email ya esta en uso");
            } else if (error.code === 'auth/invalid-email') {
                return ("Email invalido");
            } else if (error.code === 'auth/weak-password') {
                return ("La contraseña debe tener al menos 6 caracteres");
            }
            else {
                console.log(error);
                return ("Por favor comuniquese con el administrador");
            }
        }
    };

    const searchUser = async (userId, projectId) => {
        const userRef = doc(db, projectId, userId)
        const userSnap = await getDoc(userRef)
        if (userSnap.exists()) {
            return userSnap.data()
        } else {
            return null
        }
    };

    const authStatus = () => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setLoginFlag(true)
                setAuthID(user.uid)
            } else {
                setLoginFlag(false)
            }
        });
    };

    const userExists = async (userId, projectId) => {
        const userRef = doc(db, projectId, userId)
        const userSnap = await getDoc(userRef)
        if (userSnap.exists()) {
            return true
        } else {
            return false
        }
    };

    const uploadPhoto = async (file, nameFile, docuId, projectId) => {
        const storageRef = ref(storage, `${projectId}/${nameFile}`)
        try {
            await uploadBytes(storageRef, file).then(() => {
                console.log("Uploaded file!")
            });
            const url = await getDownloadURL(storageRef)
            await writePhoto(docuId, projectId, url)
            return "La foto se cargo con éxito"
        } catch (error) {
            console.log(error)
            return "Error al subir la foto"
        }
    };

    const writePhoto = async (docuId, projectId, url) => {
        const quoteRef = doc(db, `${projectId}/`, docuId)
        const modelos = { photo: url }
        try {
            await setDoc(quoteRef, modelos, { merge: true })
        } catch (error) {
            console.log(error)
        }
    };

    //Carga de banner
    const uploadBanner = async (file, nameFile, docuId, projectId) => {
        const storageRef = ref(storage, `${projectId}/${nameFile}`)
        try {
            await uploadBytes(storageRef, file).then(() => {
                console.log("Uploaded file!")
            })
            const url = await getDownloadURL(storageRef)
            await writeBanner(docuId, projectId, url)
            return "El banner se cargo con éxito"
        } catch (error) {
            console.log(error)
            return "Error al subir el banner"
        }
    };

    //Carga la ruta del banner en el perfil del usuario
    const writeBanner = async (docuId, projectId, url) => {
        const quoteRef = doc(db, `${projectId}/`, docuId)
        const modelos = { banner: url }
        try {
            await setDoc(quoteRef, modelos, { merge: true })
        } catch (error) {
            console.log(error)
        }
    }

    // Actualiza los datos del usuario
    const updateUser = async (data) => {
        const { name, lastname, phone, linkedin, puesto, userId, projectId, facebook, twitter, instagram, web, email, job, gender, template, footer, typeoffooter, footer_text, video, whats_animated = false, microsoftteams, augmented_reality, logo_promoconecta, location } = data
        if (email) {
            try {
                updateEmail(auth.currentUser, email)
            } catch (error) {
                console.log(error)
            }
        }
        const booleanwhats = whats_animated === "true" ? true : false
        const animated = {
            ...(whats_animated ? { whats_animated: booleanwhats } : { whats_animated: false })
        }
        const userRef = doc(db, projectId, userId);
        const modelo = {
            ...(name ? { name } : {}),
            ...(lastname ? { lastname } : {}),
            ...(phone ? { phone } : {}),
            ...(linkedin ? { linkedin } : {}),
            ...(puesto ? { puesto } : {}),
            ...(job ? { job } : {}),
            ...(gender ? { gender } : {}),
            ...(footer ? { footer } : null),
            ...(template ? { template } : {}),
            ...(facebook ? { facebook } : {}),
            ...(microsoftteams ? { microsoftteams } : {}),
            ...(augmented_reality ? { augmented_reality } : {}),
            ...(twitter ? { twitter } : {}),
            ...(instagram ? { instagram } : {}),
            ...(video ? { video } : {}),
            ...(web ? { web } : {}),
            ...(email ? { email } : {}),
            ...(typeoffooter ? { typeoffooter } : null),
            ...(footer_text ? { footer_text } : null),
            ...(animated ? { animated } : null),
            ...(logo_promoconecta ? { logo_promoconecta } : null),
            ...(location ? { location } : null)
        }
        if (userRef) {
            try {
                await setDoc(userRef, modelo, { merge: true });
            } catch (error) {
                console.log(error)
            }
            return "Usuario actualizado"
        } else {
            return "El usuario no existe"
        }
    };

    const deleteSocial = async (data) => {
        const { userId, projectId, social } = data;
        const userRef = doc(db, projectId, userId);
        const modelo = {
            [social]: deleteField()
        }
        if (userRef) {
            try {
                await setDoc(userRef, modelo, { merge: true });
            } catch (error) {
                console.log(error)
            }
            return "Usuario actualizado"
        } else {
            return "El usuario no existe"
        }
    };

    const projectPhoto = async (projectId) => {
        const docRef = doc(db, 'projects', projectId)
        const docSnap = await getDoc(docRef)
        const docId = docSnap.data()
        try {
            return docId
        } catch (error) {
            console.log(error)
            return "Error al cargar los proyectos"
        }
    }

    const createCited = async (data) => {
        const { name, mail, phone, message, timestamp, projectId, userId, number } = data;
        const userRef = doc(db, projectId, userId);
        if (userRef) {
            try {
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    const dataCited = userSnap.data();
                    const citedRef = doc(collection(userRef, 'cited'));
                    const newCited = {
                        id: citedRef.id,
                        name: name,
                        email: mail,
                        phone: phone,
                        message: message,
                        created_at: new Date(timestamp),
                        status_cited: 0,
                    };
                    const updatedCited = dataCited.cited ? [...dataCited.cited, newCited] : [newCited];
                    // Actualiza la colección de citas en Firestore
                    await updateDoc(userRef, { cited: updatedCited });
                    // Envía la notificación a la API
                    const notificationResponse = await fetch('http://localhost:5000/api/notification', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            name,
                            mail,
                            number,
                            timestamp: (() => {
                                const date = new Date(timestamp);
                                const day = String(date.getDate()).padStart(2, '0');        // Día (2 dígitos)
                                const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes (2 dígitos, +1 porque los meses empiezan en 0)
                                const year = date.getFullYear();                            // Año (4 dígitos)
                                const hours = String(date.getHours()).padStart(2, '0');     // Horas (2 dígitos)
                                const minutes = String(date.getMinutes()).padStart(2, '0'); // Minutos (2 dígitos)
                                // Devuelve la fecha en formato dd/mm/yyyy hh:mm
                                return `${day}/${month}/${year} en un horario de ${hours}:${minutes} ${hours >= 12 ? 'pm' : 'am'}`;
                            })(),
                        }),
                    });
                    if (!notificationResponse.ok) {
                        throw new Error('Error al enviar la notificación');
                    }
                    return "Cita creada y notificación enviada";
                } else {
                    return "El usuario no existe";
                }
            } catch (error) {
                console.error(error);
                return "Error al crear la cita";
            }
        } else {
            return "El usuario no existe";
        }
    };

    const updateStatusCited = async (data) => {
        const { projectId, userId, id, nombre, correo, fecha, numero } = data;
        const userRef = doc(db, projectId, userId);
        try {
            const userSnap = await getDoc(userRef);
            if (userSnap.exists()) {
                const dataCited = userSnap.data();
                if (dataCited.cited) {
                    // Encuentra la cita con el ID proporcionado
                    const citedIndex = dataCited.cited.findIndex(cite => cite.id === id);
                    if (citedIndex !== -1) {
                        // Obtén la fecha de la cita que se quiere actualizar
                        const targetDate = dataCited.cited[citedIndex].created_at;
                        // Verifica si ya existe otra cita con la misma fecha y status_cited igual a 1
                        const conflictingCite = dataCited.cited.find(cite =>
                            cite.created_at.seconds === targetDate.seconds &&
                            cite.created_at.nanoseconds === targetDate.nanoseconds &&
                            cite.status_cited === 1
                        );
                        if (conflictingCite) {
                            return "No se puede confirmar la cita, ya existe otra cita confirmada con esa fecha.";
                        }
                        // Actualiza el status de la cita
                        const updatedCited = dataCited.cited.map(cite =>
                            cite.id === id ? { ...cite, status_cited: 1 } : cite
                        );
                        await updateDoc(userRef, { cited: updatedCited });
                        const notificationResponse = await fetch('http://localhost:5000/api/confirmation-cited', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                nombre,
                                correo,
                                fecha,
                                numero
                            }),
                        });
                        if (!notificationResponse.ok) {
                            throw new Error('Error al enviar la notificación');
                        }
                        return "¡Cita confirmada!"
                    } else {
                        return "Cita no encontrada"
                    }
                } else {
                    return "No hay citas asociadas a este usuario"
                }
            } else {
                return "El usuario no existe"
            }
        } catch (error) {
            console.error("Error al actualizar el estado de la cita:", error)
            return "Error al actualizar el estado de la cita"
        }
    };

    const updateDateCited = async (data) => {
        const { projectId, userId, id, timestamp } = data;
        const userRef = doc(db, projectId, userId);
        try {
            const userSnap = await getDoc(userRef);
            if (userSnap.exists()) {
                const dataCited = userSnap.data();
                if (dataCited.cited) {
                    // Encuentra la cita con el ID proporcionado
                    const citedIndex = dataCited.cited.findIndex(cite => cite.id === id);
                    if (citedIndex !== -1) {
                        // Convertir `timestamp` a `Date`
                        const newDate = new Date(timestamp);
                        const newDateTimestamp = newDate.getTime();
                        // Verifica que la nueva fecha y hora no se superponga con otra cita confirmada
                        const citedOverlap = dataCited.cited.some(cite => {
                            // Convertir `created_at` de Firestore Timestamp a Date
                            const citeDate = new Date(cite.created_at.seconds * 1000); // `seconds` está en segundos
                            return cite.status_cited === 1 && cite.id !== id && citeDate.getTime() === newDateTimestamp;
                        });
                        if (!citedOverlap) {
                            // Actualiza la fecha y hora de la cita
                            const updatedCited = dataCited.cited.map(cite =>
                                cite.id === id ? { ...cite, created_at: { seconds: newDate.getTime() / 1000, nanoseconds: 0 } } : cite
                            );
                            await updateDoc(userRef, { cited: updatedCited });
                            return "¡Fecha y hora de la cita actualizada!"
                        } else {
                            return "La fecha y hora seleccionada ya está ocupada"
                        }
                    } else {
                        return "Cita no encontrada"
                    }
                } else {
                    return "No hay citas asociadas a este usuario"
                }
            } else {
                return "El usuario no existe"
            }
        } catch (error) {
            console.error("Error al actualizar la fecha y hora de la cita:", error)
            return "Error al actualizar la fecha y hora de la cita"
        }
    }

    const newFilePower = async (file, nameFile, projectId, userId) => {
        const storageRef = ref(storage, `${projectId}/files/${nameFile}`)
        try {
            await uploadBytes(storageRef, file).then(() => {
                console.log("Power cargado!")
            });
            const url = await getDownloadURL(storageRef)
            await writePower(projectId, userId, url)
            return "El archivo se cargo con éxito"
        } catch (error) {
            console.log(error)
            return "Error al subir la foto"
        }
    };

    const writePower = async (projectId, userId, url) => {
        const quoteRef = doc(db, `${projectId}/${userId}/`)
        const modelos = { powerpoint: url }
        try {
            await setDoc(quoteRef, modelos, { merge: true })
        } catch (error) {
            console.log(error)
        }
    };

    const newFileWord = async (file, nameFile, projectId, userId) => {
        const storageRef = ref(storage, `${projectId}/files/${nameFile}`)
        try {
            await uploadBytes(storageRef, file).then(() => {
                console.log("Power cargado!")
            });
            const url = await getDownloadURL(storageRef)
            await writeWord(projectId, userId, url)
            return "El archivo se cargo con éxito"
        } catch (error) {
            console.log(error)
            return "Error al subir la foto"
        }
    };

    const writeWord = async (projectId, userId, url) => {
        const quoteRef = doc(db, `${projectId}/${userId}/`)
        const modelos = { word: url }
        try {
            await setDoc(quoteRef, modelos, { merge: true })
        } catch (error) {
            console.log(error)
        }
    };

    const newFileExcel = async (file, nameFile, projectId, userId) => {
        const storageRef = ref(storage, `${projectId}/files/${nameFile}`)
        try {
            await uploadBytes(storageRef, file).then(() => {
                console.log("Power cargado!")
            });
            const url = await getDownloadURL(storageRef)
            await writeExcel(projectId, userId, url)
            return "El archivo se cargo con éxito"
        } catch (error) {
            console.log(error)
            return "Error al subir la foto"
        }
    };

    const writeExcel = async (projectId, userId, url) => {
        const quoteRef = doc(db, `${projectId}/${userId}/`)
        const modelos = { excel: url }
        try {
            await setDoc(quoteRef, modelos, { merge: true })
        } catch (error) {
            console.log(error)
        }
    };

    const newFilePdf = async (file, nameFile, projectId, userId) => {
        const storageRef = ref(storage, `${projectId}/files/${nameFile}`)
        try {
            await uploadBytes(storageRef, file).then(() => {
                console.log("Power cargado!")
            });
            const url = await getDownloadURL(storageRef)
            await writePdf(projectId, userId, url)
            return "El archivo se cargo con éxito"
        } catch (error) {
            console.log(error)
            return "Error al subir la foto"
        }
    };

    const writePdf = async (projectId, userId, url) => {
        const quoteRef = doc(db, `${projectId}/${userId}/`)
        const modelos = { pdf: url }
        try {
            await setDoc(quoteRef, modelos, { merge: true })
        } catch (error) {
            console.log(error)
        }
    };

    const deleteFile = async (data) => {
        const { projectId, userId, file } = data;
        const userRef = doc(db, projectId, userId);
        const modelo = {
            [file]: deleteField()
        }
        if (userRef) {
            try {
                await setDoc(userRef, modelo, { merge: true });
            } catch (error) {
                console.log(error)
            }
            return "Archivo eliminado"
        } else {
            return "El usuario no existe"
        }
    }

    return (
        <FirebaseFuntions.Provider value={{
            loginStatus,
            loginFlag,
            idDoc,
            authID,
            userProject,
            projectPhoto,
            userExists,
            deleteSocial,
            uploadBanner,
            writeBanner,
            setAuthID,
            updateUser,
            uploadPhoto,
            authStatus,
            setIdDoc,
            searchUser,
            setLoginFlag,
            createUser,
            userForgotPassword,
            userLogout,
            setLoginStatus,
            userLogin,
            readAllProjects,
            createCited,
            updateStatusCited,
            updateDateCited,
            newFilePower,
            newFileWord,
            newFileExcel,
            newFilePdf,
            deleteFile
        }}>
            {children}
        </FirebaseFuntions.Provider>
    );
}

export default FirebaseContext;