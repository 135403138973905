import React, { useMemo, useState } from "react"
import { motion } from "framer-motion"
import carnot_logo from "../../../assets/templates/carnot/carnot_logo.png"
import profile from "../../../assets/social_icons/profile.png"
import generateVcard from "../../../functions/generateVcard"
import QrModal from "../../../components/QrModal/QrModal"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "../../../components/Slider/Carousel"

const MainCointainer = ({ user, typeOfDevice, projectLogo }) => {

    const { name, lastname, email, phone, linkedin, photo, facebook, instagram, twitter, web, job, video, microsoftteams, animated
        , excel, powerpoint, pdf, word
    } = user
    const [showQr, setShowQr] = useState(false)
    const url = useMemo(() => {
        const data = generateVcard(user)
        return data
    }, [user])
    const handleQR = () => {
        setShowQr(!showQr)
    }
    const socialStyle = "flex justify-center drop-shadow-lg mt-2"
    const imageStyle = "xl:w-[120px] xl:h-[120px] sm:w-20 sm:h-20 flex content-center p-2"

    const socialLinks = [
        phone,
        email,
        linkedin,
        facebook,
        instagram,
        twitter,
        video,
        microsoftteams
    ].filter(link => link !== undefined && link !== null);

    let infinite = false;

    if (socialLinks.length >= 4) {
        infinite = true;
    }
    if (socialLinks.length <= 3) {
        infinite = false;
    }

    const sliderSettings = {
        dots: false, // Muestra los puntos de navegación
        infinite: infinite, // Permite un desplazamiento infinito
        speed: 500, // Velocidad de transición en milisegundos
        slidesToShow: 3, // Número de elementos mostrados a la vez
        slidesToScroll: 1, // Número de elementos desplazados en cada cambio
        // centerMode: false, // Centra el elemento activo
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3, // Cambia el número de elementos mostrados en dispositivos más pequeños
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3, // Cambia el número de elementos mostrados en dispositivos aún más pequeños
                },
            },
        ],
    };

    return (
        <main
            className="flex flex-col md:p-20 sm:p-5 bg-black xl:h-full lg:h-full md:h-screen xs:h-full">
            <section className="flex flex-col justify-center items-center">
                <motion.img
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, ease: "easeInOut" }}
                    className="mx-auto drop-shadow-lg" src={projectLogo ? projectLogo : carnot_logo} alt="carnot" />
            </section>

            <section className="flex md:justify-center md:flex-col sm:flex-col sm:justify-center items-center">
                <QrModal
                    showQr={showQr}
                    setShowQr={setShowQr}
                    url={window.location.href}
                    fullname={`${name} ${lastname}`}
                />
                {/* INFORMACION PERSONAL */}
                <div className="relative w-full ">
                    <div className="absolute bg-white border border-carnot-blue w-full rounded-lg shadow-lg top-1/4" style={{ height: "78%" }}>
                        <motion.div
                            initial={{ opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1, ease: "easeInOut", delay: 1.3 }}>
                        </motion.div>
                    </div>
                    <>
                        <motion.div
                            className="flex flex-col items-center justify-center">
                            <img className="md:w-56 md:mt-0 sm:mt-5 sm:w-40 rounded-full mx-auto drop-shadow-lg border-4 border-carnot-green"
                                src={photo ? photo : profile} alt="profile" />
                            {/* <QR></QR> */}
                            <motion.button
                                onClick={handleQR}
                                title="Ver QR"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                style={{ filter: "none" }}
                                className="relative flex flex-col items-center justify-center bg-carnot-green rounded-full p-2 -top-5">
                                <svg width="20" height="20" fill="none" stroke="#ffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#ffff" stroke="none" d="M19.125 15.75h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                    <path fill="#ffff" stroke="none" d="M15.375 12.75h-2.25a.375.375 0 0 0-.375.375v2.25c0 .207.168.375.375.375h2.25a.375.375 0 0 0 .375-.375v-2.25a.375.375 0 0 0-.375-.375Z"></path>
                                    <path fill="#ffff" stroke="none" d="M22.125 19.5h-2.25a.375.375 0 0 0-.375.375v2.25c0 .207.168.375.375.375h2.25a.375.375 0 0 0 .375-.375v-2.25a.375.375 0 0 0-.375-.375Z"></path>
                                    <path fill="#ffff" stroke="none" d="M22.125 12.75h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375Z"></path>
                                    <path fill="#ffff" stroke="none" d="M14.625 20.25h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375Z"></path>
                                    <path fill="#ffff" stroke="none" d="M19.125 4.5h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                    <path d="M21 2.25h-6.75a.75.75 0 0 0-.75.75v6.75c0 .414.336.75.75.75H21a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75Z"></path>
                                    <path fill="#ffff" stroke="none" d="M7.875 4.5h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                    <path d="M9.75 2.25H3a.75.75 0 0 0-.75.75v6.75c0 .414.336.75.75.75h6.75a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75Z"></path>
                                    <path fill="#ffff" stroke="none" d="M7.875 15.75h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                    <path d="M9.75 13.5H3a.75.75 0 0 0-.75.75V21c0 .414.336.75.75.75h6.75a.75.75 0 0 0 .75-.75v-6.75a.75.75 0 0 0-.75-.75Z"></path>
                                </svg>
                            </motion.button>
                        </motion.div>
                        {/* DATOS */}
                        <div className="leading-tight mb-4 flex flex-col justify-center items-center space-y-3 xs:mt-3 lg:mt-0">
                            <motion.h1
                                initial={{ opacity: 0, y: -50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 1, ease: "easeInOut", delay: 0.8 }}
                                className="md:text-4xl sm:text-2xl font-bold text-center text-carnot-blue  font-heading">{name} {lastname}</motion.h1>
                            {
                                job &&
                                <motion.h2
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1 }}
                                    className="md:text-2xl sm:text-lg text-center text-carnot-blue uppercase font-body">{job}</motion.h2>

                            }
                            {
                                <motion.button
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1.3 }}
                                    className="bg-carnot-green text-white font-bold py-2 px-4 rounded-lg text-center"
                                >
                                    <a
                                        href={URL.createObjectURL(url)}
                                        download={`${String(name + lastname)
                                            .normalize("NFD")
                                            .replace(/[\u0300-\u036f]/g, "")}.vcf`}
                                        className="text-white"

                                    >
                                        GUARDAR CONTACTO
                                    </a>
                                </motion.button>
                            }
                            {web &&
                                <motion.a
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1.3 }}
                                    className="text-xl text-center" href={web.includes("https://") ? `${web}` : `https://${web}/`}>
                                    <span className="text-carnot-blue">{web.includes("https://") ? web.replace("https://", "") : web}</span>
                                </motion.a>
                            }
                        </div>
                    </>
                </div>
            </section>

            <section className="md:mt-10 xs:mt-10 xs:mb-7 md:mb-7 lg:mb-7 xl:mb-7">
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, ease: "easeInOut", delay: 1.6 }}
                    className="slider-carnot custom-container"
                >
                    <Carousel
                        socialStyle={socialStyle}
                        imageStyle={imageStyle}
                        sliderSettings={sliderSettings}
                        email={email}
                        phone={phone}
                        linkedin={linkedin}
                        microsoftteams={microsoftteams}
                        facebook={facebook}
                        instagram={instagram}
                        twitter={twitter}
                        video={video}
                        animated={animated}
                        typeOfDevice={typeOfDevice}
                        excel={excel}
                        powerpoint={powerpoint}
                        pdf={pdf}
                        word={word}
                    />
                </motion.div>
            </section>

            <section className="md:mb-0 sm:mb-5">
                <div className="flex flex-row justify-between">
                    <motion.a
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1, ease: "easeInOut", delay: 1.9 }}
                        className="uppercase bg-white text-carnot-blue border-2 border-carnot-green w-1/2 p-4 rounded-lg text-base font-semibold text-center hover:bg-carnot-green hover:text-carnot-blue inline-flex items-center justify-center"
                        href="https://firebasestorage.googleapis.com/v0/b/promo-nfc.appspot.com/o/carnot%2Fpdf%2FFolleto%20Letipret%20v10%20dis.pdf?alt=media&token=fb901dbb-4189-4cf1-b7ca-bdbebe6338d4"
                        target="_blank" rel="noreferrer"
                    >
                        <svg width={23} height={23} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.5 2.25v19.5h15V2.25h-15ZM6 3.75h12v16.5H6V3.75Zm5.555 4.008a1.082 1.082 0 0 0-.68.234c-.19.161-.275.36-.328.563-.103.404-.073.823.023 1.289.115.545.44 1.2.703 1.828-.134.571-.17 1.078-.375 1.664-.175.504-.4.794-.609 1.242-.472.179-1.034.284-1.406.516-.402.249-.753.524-.961.914-.208.39-.185.94.094 1.336.137.208.319.372.562.468.243.097.507.1.727.024.442-.152.755-.492 1.054-.89.279-.37.475-.997.727-1.5.378-.127.65-.285 1.055-.376.421-.093.706-.05 1.101-.093.17.193.314.503.492.656.358.31.75.556 1.22.586.468.029.937-.264 1.194-.703h.024v-.024c.114-.199.193-.416.187-.656a1.047 1.047 0 0 0-.28-.656c-.309-.328-.701-.413-1.126-.47-.328-.043-.785.074-1.172.095-.34-.449-.677-.785-.984-1.36-.167-.31-.211-.574-.352-.89.109-.51.323-1.078.352-1.524.035-.539.014-1.005-.14-1.43a1.37 1.37 0 0 0-.4-.585 1.12 1.12 0 0 0-.679-.258h-.023Zm.492 5.554c.135.238.302.387.445.61-.21.038-.372 0-.586.047-.035.008-.058.038-.094.047.044-.118.1-.188.141-.305.047-.138.05-.26.094-.399Zm2.765 1.524c.253.032.343.079.376.094-.006.011.008.008 0 .023-.094.155-.103.144-.165.14-.05-.002-.24-.105-.421-.234.052.003.164-.029.21-.023Zm-5.25 1.172c-.04.061-.076.205-.117.258-.228.304-.44.445-.492.468-.009-.011.015 0 0-.023H8.93c-.076-.108-.056-.064 0-.164.055-.1.231-.302.539-.492.023-.015.07-.032.094-.047Z" />
                        </svg>
                        LETIPRET
                    </motion.a>
                    <motion.a
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1, ease: "easeInOut", delay: 1.9 }}
                        className="uppercase bg-white text-carnot-blue border-2 border-carnot-green w-1/2 p-4 rounded-lg text-base font-semibold text-center hover:bg-carnot-green hover:text-carnot-blue inline-flex items-center justify-center"
                        href="https://firebasestorage.googleapis.com/v0/b/promo-nfc.appspot.com/o/carnot%2Fpdf%2FFolleto%20Solvopret%20v9%20dis.pdf?alt=media&token=8003341c-2c33-4678-8dc3-1e86ae3a7c21"
                        target="_blank" rel="noreferrer"
                    >
                        <svg width={23} height={23} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.5 2.25v19.5h15V2.25h-15ZM6 3.75h12v16.5H6V3.75Zm5.555 4.008a1.082 1.082 0 0 0-.68.234c-.19.161-.275.36-.328.563-.103.404-.073.823.023 1.289.115.545.44 1.2.703 1.828-.134.571-.17 1.078-.375 1.664-.175.504-.4.794-.609 1.242-.472.179-1.034.284-1.406.516-.402.249-.753.524-.961.914-.208.39-.185.94.094 1.336.137.208.319.372.562.468.243.097.507.1.727.024.442-.152.755-.492 1.054-.89.279-.37.475-.997.727-1.5.378-.127.65-.285 1.055-.376.421-.093.706-.05 1.101-.093.17.193.314.503.492.656.358.31.75.556 1.22.586.468.029.937-.264 1.194-.703h.024v-.024c.114-.199.193-.416.187-.656a1.047 1.047 0 0 0-.28-.656c-.309-.328-.701-.413-1.126-.47-.328-.043-.785.074-1.172.095-.34-.449-.677-.785-.984-1.36-.167-.31-.211-.574-.352-.89.109-.51.323-1.078.352-1.524.035-.539.014-1.005-.14-1.43a1.37 1.37 0 0 0-.4-.585 1.12 1.12 0 0 0-.679-.258h-.023Zm.492 5.554c.135.238.302.387.445.61-.21.038-.372 0-.586.047-.035.008-.058.038-.094.047.044-.118.1-.188.141-.305.047-.138.05-.26.094-.399Zm2.765 1.524c.253.032.343.079.376.094-.006.011.008.008 0 .023-.094.155-.103.144-.165.14-.05-.002-.24-.105-.421-.234.052.003.164-.029.21-.023Zm-5.25 1.172c-.04.061-.076.205-.117.258-.228.304-.44.445-.492.468-.009-.011.015 0 0-.023H8.93c-.076-.108-.056-.064 0-.164.055-.1.231-.302.539-.492.023-.015.07-.032.094-.047Z" />
                        </svg>
                        SOLVOPRET
                    </motion.a>
                </div>

                <motion.a
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, ease: "easeInOut", delay: 1.9 }}
                    className="uppercase bg-white text-carnot-blue border-2 border-carnot-green w-full p-4 rounded-lg text-base inline-flex items-center font-semibold mt-5 hover:bg-carnot-green hover:text-carnot-blue justify-center"
                    href="https://ar.makinntap.com/prod/proyect1" target="_blank" rel="noreferrer">
                    <svg className="mr-5" width={40} height={40} fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="m6 17.6-2-1.1V14" />
                        <path d="M4 9.998v-2.5l2-1.1" />
                        <path d="M10 4.1 12 3l2 1.1" />
                        <path d="m18 6.398 2 1.1v2.5" />
                        <path d="M20 14v2.5l-2 1.12" />
                        <path d="m14 19.898-2 1.1-2-1.1" />
                        <path d="m12 11.998 2-1.1" />
                        <path d="m18 8.6 2-1.1" />
                        <path d="M12 12v2.5" />
                        <path d="M12 18.5V21" />
                        <path d="m12 12.003-2-1.12" />
                        <path d="M6 8.6 4 7.5" />
                    </svg>
                    Realidad Aumentada
                </motion.a>
            </section>
        </main >
    )
}

export default MainCointainer